import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartLine } from 'react-icons/fa';  // Asegúrate de tener react-icons instalado

const TerminalCard = ({ terminal }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/graficos/${terminal.guid}`);  // Redirige a la página de gráficos con el GUID
  };

  return (
    <div 
      className="bg-green-500 p-4 rounded-lg shadow-md cursor-pointer hover:bg-green-600 transition"
      onClick={handleClick}  // Hacer clic en el card redirige a la página de gráficos
    >
      <h2 className="text-xl font-bold mb-2">{terminal.guid}</h2>
      <p>Temperatura: {terminal.temperatura} °C</p>
      <p>Humedad: {terminal.humedad} %</p>
      <p>Temperatura del Suelo: {terminal.temperatura_suelo} °C</p>
      <p>Humedad del Suelo: {terminal.humedad_suelo} %</p>
      <p>Capacitor: {terminal.capacitor} </p>

      <div className="mt-4">
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center hover:bg-blue-600 transition"
        >
          <FaChartLine className="mr-2" /> Ver Gráficos
        </button>
      </div>
    </div>
  );
};

export default TerminalCard;